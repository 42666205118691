/* ImageStyleSelection.css */

  
  .image-style-container {
    padding: 30px;
    max-width: 1200px;
    margin: 0 auto;
    color: white;
    text-align: center;
  }
  
  .section {
    margin-bottom: 60px;
  }
  
  /* .heading {
    padding: 10px;
    font-size: 2rem;
    font-weight: bold;
    text-decoration: underline;
    text-decoration-thickness: 2px;
  } */
  
  .text {
    font-size: 1.2rem;
    line-height: 1.4;
    color: white;
  }

  .yellow-text{
    font-size: 1.4rem;
    line-height: 1.4;
    color: #eeb400;
  }
  
  .highlight {
    color: #00b894; /* Mint color */
  }

  .highlighting {
    background-color: #00A4AB;
    padding: 0 5px;
    color: #222932; /* Corrected here */
  }
  
  @media (max-width: 768px) {
    .heading {
      font-size: 1.5rem;
    }
    .text {
      font-size: 1rem;
    }
  }
  
  @media (max-width: 480px) {
    .heading {
      font-size: 1.2rem;
    }
    .text {
      font-size: 0.9rem;
    }
  }
  