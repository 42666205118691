:root {
    --responsive-font-size: 4vw;
  }
  
  .squareTitle {
    position: relative;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #00A4AB;
    font-weight: bold;
    border: solid 6px white;
    box-shadow: -8px -8px 0px 1px #00A4AB;
    padding: 5px;  /* Adjusted padding */
    margin: 10px;  /* Adjusted margin */
    box-sizing: border-box;
    width: fit-content;
  }
  
  /* .font-first {
    word-wrap: break-word;
    text-align: center;
    font-size: var(--responsive-font-size);
  } */

  .titeling{
    word-wrap: break-word;
   
    text-align: center
  }
  
  .container {
    padding: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  
  @media (max-width: 1200px) {
    :root {
      --responsive-font-size: 3vw;
    }
  }
  
  @media (max-width: 992px) {
    :root {
      --responsive-font-size: 4vw;
    }
  }
  
  @media (max-width: 768px) {
    :root {
      --responsive-font-size: 5vw;
    }
  }
  
  @media (max-width: 576px) {
    :root {
      --responsive-font-size: 6vw;
    }
  }
  