#dextools-widget{
    margin-top: 24px;
}

.heading {
    font-size: 4rem;
    font-weight: bold;
    margin-bottom: 20px;
    text-underline-offset: 10px;
    text-decoration: underline;
    text-decoration-color: red;
    text-decoration-thickness: 3px;
    
  }