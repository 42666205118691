@tailwind base;
@tailwind components;
@tailwind utilities;


/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/* For Webkit (Chrome, Safari, newer versions of Opera and Edge) */
::-webkit-scrollbar {
  width: 6px; /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: transparent; /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  background-color: #f29ac0; /* color of the scroll thumb */
  border-radius: 3px; /* roundness of the scroll thumb */
  border: none; /* creates padding around scroll thumb */
}

/* For Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #a0bcc2 transparent; /* thumb and track color */
}


@font-face {
  font-family: 'kapsalon';
  src: url('/public/fonts/KapsalonDEMO.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'kapsalonSecond';
  src: url('/public/fonts/Kapsalon\ Print\ DEMO.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

html {
  scroll-behavior: smooth;
}

html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.font-first {
  /* word-wrap: break-word; */
  text-align: center;
  font-size: var(--responsive-font-size);
}

.section {
  min-height: 40vh;
  padding-top: 2%; /* To adjust for fixed nav */
}

#section5{
  padding-bottom: 2%;
  margin-bottom: 0%;
}

#section3Inside{ 
  margin: 0 auto;
  max-width: 1200px;
}

.dashed-divider{
  background-color: 'transparent'; 
  margin-top: '30px';
  display: 'flex'; 
  flex-direction: 'column'; 
  justify-content: 'center';
}


.custom-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  background-color: #2c3e50;
  border: 2px solid #00ffff;
  box-sizing: border-box;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.custom-text {
  font-size: 24px;
  font-weight: bold;
  color: #00ffff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  margin: 0;
}

.coms p{
  margin: 10px;
  font-size: 1.2rem;
  line-height: 1.4;
}

.coms {
  width: 100%; /* Default width */
  margin: 0 auto; /* Center the container horizontally */
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 640px) {
  .coms {
    width: 15%; /* Width for small screens and up */
  }
}

@media (min-width: 768px) {
  .coms {
    width: 10%; /* Width for medium screens and up */
  }
}

@media (min-width: 1024px) {
  .coms {
    width: 30%; /* Width for large screens and up */
  }
}

.text {
  font-size: 1.2rem;
  line-height: 1.4;
}

.top-nav-bar-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.top-bar-wrapper {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}

.top-bar {
  display: flex;
  animation: scroll 20s linear infinite;
  white-space: nowrap; /* Ensure text stays in one line */
  height: 40px;
}

.scrolling-text {
  padding: 0 5rem;
  margin: 6px auto;
  color: #f2cba7; /* Your specified color */
  text-align: center;
  font-size: 24px;
}

/* Keyframes for scrolling effect */
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.nav-buttons-wrapper {
  width: 100%;
  background-color: #222932; /* Ensure the background color matches your theme */
  position: relative;
  z-index: 1; /* Ensure it stays above the top bar */
}



/* IFRAME */

/* Add this to your CSS file */

.parent-container {
  margin: 0;
  padding: 0;
}

.iframe-container {
  margin-top: 0;
  padding: 0;
  display: block; /* Ensures it behaves like a block-level element */
  width: 100%;
}
/* Add this to your CSS file */

.iframe-container {
  margin: 0;
  padding: 0;
}
