.font-smaller{
  font-size: 20px;
}

.introduction-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    color: white;
    text-align: center;
  }
  
  .title {
    font-size: 3rem;
    font-weight: bold;
    text-align: center;
    position: relative;
    margin-bottom: 20px;
    text-decoration-line: 2px underline red;
  }


  .heading {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 20px;
    text-decoration: underline;
    text-decoration-color: red;
    text-underline-offset: 10px;
    text-decoration-thickness: 6px;
  }
  
  .title:hover::after {
    width: 50%;
    margin: 0 auto;
  }
  
  .contents {
    font-size: 10.4rem;
    line-height: 1.2;
  }
  
  .highlight {
    color: #02a3ab; /* Mint color */
  }
  
  .quote {
    color: #02a3ab; /* Mint color */
    font-style: italic;
  }
  
  .tool {
    background-color: #222932;
    padding: 0 5px;
    color: #00A4AB; /* Corrected here */
}

.highlighter {
  background-color: #efb500;
  color: white;
  padding: 0 0px;
  /* margin: 2px; */
  text-decoration: none;
}

  
  @media (max-width: 768px) {
    .title {
      font-size: 2.0rem !important;
    }
    .content {
      font-size: 1rem;
    }
  }
  
  @media (max-width: 480px) {
    .title {
      font-size: 2.0rem !important;
    }
    .content {
      font-size: 0.9rem;
    }
  }
  