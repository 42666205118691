/* TitleComponent.css */

.title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 0; /* Adjust padding to reduce space around the title */
  flex-wrap: wrap; /* Allow wrapping on small screens */
  margin: 0; /* Remove any additional margin */
}

.title-container span {
  font-size: 4rem;
  font-weight: bold;
  padding: 0 5px; /* Adjust padding to reduce space between words */
  margin: 0; /* Remove any additional margin */
}

.text-white {
  color: white;
}

.text-mint {
  color: #00A4AB;
}

.api-tool {
  background-color: #efb500;
  padding: 0 20px;
  border-radius: 5px;
  margin: 0; /* Remove any additional margin */
}


@media (max-width: 768px) {
  .title-container span {
    font-size: 2rem;
    padding: 0 5px; /* Adjust padding for smaller screens */
  }
}

@media (max-width: 480px) {
  .title-container span {
    font-size: 1.5rem;
    padding: 0 2px; /* Adjust padding for even smaller screens */
  }
}
