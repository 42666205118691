/* StyledTextWithEffects.css */

.text-effects-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    color: white;
    text-align: center;
  }
  
  .text-line {
    font-size: 1.6rem;
    color: white;
  }
  
  .strikethrough {
    text-decoration: line-through;
    text-decoration-color: red;
  }
  
  .highlight {
    background-color: #efb500;
    color: white;
    padding: 0 0px;
    margin: 10px;
    text-decoration: none;
  }
  
  @media (max-width: 768px) {
    .text-line {
      font-size: 1.2rem;
    }
    .my-gif {
      width: 1500px;
    }
  }
  
  @media (max-width: 480px) {
    .text-line {
      font-size: 1rem;
    }
    .my-gif {
      width: 1500px;
    }
  }
  