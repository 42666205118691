  /* Styles for Image Slider Component */
  
  .slider-container {
    position: relative;
    width: 100%;
    max-width: 1000px; /* Adjust max-width as needed */
    height: 70vw; /* Responsive height based on viewport width */
    max-height: 500px; /* Ensure it doesn't get too tall on large screens */
    overflow: hidden;
  }
  
  .image,
  .top-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .top-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  
  .slider {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 5px;
    background-color: transparent;
    cursor: ew-resize;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .dotted-line {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 2px; /* Thickness of the dotted line */
    background: repeating-linear-gradient(
      to bottom,
      #fff,
      #fff 5px,
      transparent 5px,
      transparent 10px
    );
    transform: translateX(-50%);
  }
  
  .slider-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    color: white; /* Adjust icon color as needed */
    font-size: 24px; /* Adjust icon size as needed */
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    border-radius: 50%; /* Make it circular */
    padding: 10px; /* Adjust padding to make the circle larger */
    border: 2px solid white; /* White border around the circle */
  }
  

  .no-wrap {
    white-space: nowrap;
  }
  