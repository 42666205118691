.footer-container {
  padding: 20px;
  text-align: center;
}

.footer-links {
  display: flex;
  justify-content: center;
  gap: 40px;
  margin: 20px 0;
}

.footer-link {
  font-size: 1.2rem;
  color: #03a2ab;
  text-decoration: underline;
  text-decoration-thickness: 2px;
  letter-spacing: 2px; /* Added letter-spacing */
}

.footer-link:hover {
  color: #fff;
}

@media (max-width: 768px) {
  .footer-link {
    font-size: 0.9rem;
    letter-spacing: 1.0px; /* Adjusted for smaller screens */
  }
}

@media (max-width: 480px) {
  .footer-link {
    font-size: 0.5rem;
    letter-spacing: 1px; /* Adjusted for even smaller screens */
  }

}
