.how-to-buy-container {
  padding: 10px;
  text-align: center;
  color: white;
  border-radius: 20px;
}

.how-to-buy-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: nowrap;
}

.image-container {
  width: 90%;
  padding-left: 80px;
  padding-right: 80px;
  padding-top: 10px;
  padding-bottom: 5px;
}

.imageion {
  width: 100%;
  border-radius: 10px;
}

.steps-container {
  width: 55%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.step {
  background-color: transparent;
  padding: 10px;
  border-radius: 10px;
  width: 110%;
}

.step h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #00b894;
}

.step p {
  font-size: 1.2rem;
  line-height: 1.4;
}

.highlights {
  color: #02a3ab;
  text-decoration: underline;
  text-decoration-color: red;
  text-decoration-thickness: 2px;
}

@media (max-width: 1024px) {
  .steps-container {
    width: 50%;
  }
}

@media (max-width: 768px) {
  .heading {
    font-size: 1rem;
  }

  .how-to-buy-content {
    flex-direction: column;
    align-items: center;
  }

  .steps-container {
    width: 100%;
  }

  .step {
    width: 100%;
  }

  .image-container {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 480px) {
  .heading {
    font-size: 1.5rem;
  }

  .step h3 {
    font-size: 1.2rem;
  }

  .step p {
    font-size: 1rem;
  }

  .image-container {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}
