/* Community.css */

.community-container {
    /* padding: 20px; */
    max-width: 1000px;
    margin: 0 auto;
    color: white;
    text-align: center;
  }
  
  /* .headings {
    font-size: 4rem;
    font-weight: bold;
    margin-bottom: 20px;
    text-decoration: underline;
    text-decoration-color: red;
    text-decoration-thickness: 2px;
  } */
  
  .image-container {
    width: 100%;
    margin: 20px 0; 
    margin: 0 auto;
  }
  
  .community-image {
    width: 80%;
    max-width: 1200px;
    border-radius: 10px;
    margin: 0 auto;
  }
  
  .community-text {
    font-size: 1.2rem;
    line-height: 1.6;
    max-width: 1600px;
    margin: 0 auto;
  }
  
  /* .highlights {
    color: #02a3ab;
    text-decoration: underline;
    text-decoration-color: red;
    text-decoration-thickness: 2px;
  } */
  
  @media (max-width: 768px) {
    .heading {
      font-size: 2rem !important;
    }
  
    .community-text {
      font-size: 1rem;
    }
    .community-container{
      padding: 0;
    }
  }
  
  @media (max-width: 480px) {
    .heading {
      font-size: 2rem !important;
    }
  
    .community-text {
      font-size: 0.9rem;
    }

    .community-container{
      padding: 0;
    }
  }
  