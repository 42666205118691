.scrolling-text {
  margin: 6px auto;
  color: #f2cba7;
  text-align: center;
  font-size: 24px;
}

.hops:hover {
  color: #03a2ab;
}

.navbar-wrapper {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: #393E46;
}

.marquee-container {
  background-color: #444;
  color: white;
  padding: 5px 0;
}

.navbar {
  background-color: #393E46;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  max-width: 1200px;
}

.nav-buttons {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.nav-buttons button {
  background-color: #393E46;
  border: none;
  padding: 10px 20px;
  color: white;
  outline: none;
}

.nav-buttons button:hover {
  background-color: transparent;
}

.socials-dropdown {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #393E46;
  min-width: 100px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  color: white;
  padding: 10px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a:hover {
  background-color: #03a2ab;
}

.socials-dropdown .open {
  display: block;
}

.hamburger {
  display: none;
  cursor: pointer;
}

@media (max-width: 768px) {
  .nav-buttons {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 50px;
    right: 0;
    background-color: #333;
    width: 100%;
    padding: 10px 0;
    gap: 10px;
    text-align: center;
  }

  .nav-buttons.open {
    display: flex;
  }

  .hamburger {
    display: block;
  }
}
